import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import CardMissionsBuisiness from "./CardMissionsBuisiness";

const MissionsBusinessMobile: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <section className={classes.container}>
        <section className={classes.cardContainer}>
          <div className={classes.box}></div>
          <div>
            <div className={classes.titleCardContainer}>
              <span className={classes.txtPrincipal}>Objetivos Empresa</span>
            </div>
            <div className={classes.containerInfo}>
              <CardMissionsBuisiness
                reward="1% de las ventas"
                title="Lograr 100 millones en ventas"
                count={35}
              />
              <CardMissionsBuisiness
                reward="1% de las ventas"
                title="Lograr 200 millones en ventas"
                count={0}
                disable
              />
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default MissionsBusinessMobile;

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    justifyContent: "center",
    margin: "0 auto",
    height: "50rem",
    paddingTop: "3rem",
    width: 380,
    "& .disable": {
      opacity: 1,
    },
  },
  box: {
    background:
      "linear-gradient(161deg, rgb(0, 170, 188) 30%, rgb(5, 130, 149) 63%, rgb(10, 86, 105) 88%)",
    height: 500,
    width: "100%",
    position: "absolute",
    zIndex: -1,
    top: 50,
    borderRadius: "0 0 20px 20px",
  },
  cardContainer: {
    background: "#F4F3F3",
    width: "100%",
    height: 120,
    borderRadius: 50,
    boxShadow: "20px 12px 50px 0 rgba(0, 0, 0, 0.2)",
    marginBottom: 100,
    position: "relative",
    "& >div": {
      margin: "0 auto",
    },
  },
  titleCardContainer: {
    display: "flex",
    alignItems: "center",
    height: 120,
  },
  txtPrincipal: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "2rem",
    fontWeight: "bold",
    padding: "0 10px",
    color: theme.palette.text.primary,
  },
  containerInfo: {
    gridTemplateRows: "auto",
    gap: 50,
    minWidth: 380,
    marginTop: "4rem",
    margin: "0 auto",
    height: 400,
  },
  present: {
    width: 70,
    height: 70,
    position: "absolute",
    top: 10,
    left: 7,
  },
  present1: {
    width: 70,
    height: 70,
    position: "absolute",
    top: 10,
    left: 7,
  },
  lavel2: {
    width: 90,
    position: "absolute",
    top: -48,
    zIndex: 9,
    right: 0,
  },
  row: {
    width: "100%",
    height: 20,
    flexGrow: 0,
    borderRadius: 35,
    backdropFilter: "blur(20px)",
    boxShadow: "4px 4px 15px 5px rgba(0,0,0,0.25)",
    background: "#F4F3F3",
  },
  title: {
    position: "absolute",
    top: 22,
    left: 30,
    color: "white",
  },
  white: {
    color: "white",
  },
}));
