import React from "react";
import { PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { LayoutOnBoarding, SEO } from "@components";
import MissionsBusiness from "@components/OnBoard/MissionsBuisiness";
import MissionsBusinessMobile from "@components/OnBoard/MissionsBusiness";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { useAuth } from "@hooks";

const SaveByRokinPage: React.FC<PageProps> = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1280px)");
  useAuth({ redirectIfNotLogged: true });
  return (
    <>
      <SEO />
      {isMobile ? (
        <LayoutMobileInvestments>
          <MissionsBusinessMobile />
        </LayoutMobileInvestments>
      ) : (
        <LayoutOnBoarding activeBenefit>
          <MissionsBusiness />
        </LayoutOnBoarding>
      )}
    </>
  );
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({
  stepper: {
    height: 100,
    marginTop: "2rem",
    background: "none",
    width: "100%",
    maxWidth: 945,
    paddingRight: 100,
    paddingLeft: "4rem",
    margin: "0 auto",
  },
}));
